<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <div class="bg-white poppins">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Download Plugin</h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <div class="row mb-6">
          <v-col cols="12">
            <!-- <h6>1. Download the plugin from below.</h6>
            <div class="mt-6">
              <b-btn variant="primary" class="purple" @click="downloadScript"
                >Download File</b-btn
              >
            </div> -->
            <h6 class="mb-6">
              1. Integrate the script to your platform as following
            </h6>
            <div class="highlight">
              <button class="highlight-copy btn" @click="copyScript">
                {{ copyScriptButtonText }}
              </button>
              <div class="highlight-code">
                <span class="token-tag">{{ script_open }}</span>
                <span class="token-value">{{ script_type }} </span>
                <br />
                <span class="token-value">{{ script_src }}</span>
                <br />
                <span class="token-tag">{{ close_tag }}</span>
                <br />
                <span class="token-tag">{{ script_close }}</span>
                <br />
                <span class="token-tag">{{ script_open }}</span>
                <br />
                <span class="token-attr ml-6"> {{ snippet_1 }}</span>
                <br />
                <span class="token-tag ml-8">{{ snippet_20 }}</span>
                <span class="token-value ml-2">{{ snippet_3 }}</span>
                <br />
                <span class="token-tag ml-8">{{ snippet_21 }}</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_2 }}</span>
                <span class="token-value ml-2">'{{ client_token }}',</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_12 }}</span>
                <span class="token-value ml-2">{{ snippet_13 }}</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_4 }}</span>
                <span class="token-value ml-2">{{ snippet_5 }}</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_6 }}</span>
                <span class="token-value ml-2">{{ snippet_7 }}</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_8 }}</span>
                <span class="token-value ml-2">{{ snippet_9 }}</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_10 }}</span>
                <span class="token-value ml-2">{{ snippet_11 }}</span>
                <br />
                <span class="token-tag ml-12">{{ snippet_14 }}</span>
                <span class="token-value ml-2">{{ snippet_15 }}</span>
                <br />
                <span class="token-tag ml-8">{{ snippet_22 }}</span>
                <br />
                <span class="token-attr ml-4"> {{ snippet_16 }}</span>
                <br />
                <span class="token-tag"> {{ script_close }}</span>
              </div>
            </div>
            <h6 class="mt-10 mb-6">
              3. Render plugin in the body with proper div id
            </h6>
            <div class="highlight">
              <button class="highlight-copy btn" @click="copyDiv">
                {{ copyScriptDivText }}
              </button>
              <span class="token-tag">
                <span class="token-tag"> {{ snippet_17 }}</span>
                <span class="token-value">{{ snippet_18 }}</span>
                <span class="token-tag">{{ snippet_19 }}</span>
              </span>
              <br />
            </div>
          </v-col>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
// import ApiService from "@/core/services/api.service";
// import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

export default {
  name: "DownloadPlugin",
  components: {},
  data: function () {
    return {
      dialog: false,
      script_open: "<script",
      script_type: ` type="text/javascript"`,
      script_src:
        'src="https://test.api.iqhybrid.com/files/spl_return_plugin/iq-return.min.js"',
      close_tag: ">",
      script_close: `</scr` + `ipt>`,
      snippet_1: `     new IqReturn({`,
      snippet_2: "clientId:",
      snippet_3: "'__iq__return',",
      snippet_12: "inputTextColor:",
      snippet_13: "'#292930',",
      snippet_4: "bgColor:",
      snippet_5: "'#ffffff',",
      snippet_6: "headerTextColor:",
      snippet_7: "'#292930',",
      snippet_8: "btnBgColor:",
      snippet_9: "'#8c71db',",
      snippet_10: "btnTextColor:",
      snippet_11: "'#ffffff',",
      snippet_14: "lang:",
      snippet_15: "'en'",
      snippet_16: "});",
      snippet_17: "<div",
      snippet_18: ` id="__iq__return"`,
      snippet_19: "></div>",
      snippet_20: `id: `,
      snippet_21: `options: {`,
      snippet_22: `}`,
      copyScriptButtonText: "copy",
      copyScriptDivText: "copy",
      // snippet_integrate_js: this.script_open,
    };
  },
  methods: {
    toggleModal() {
      this.dialog = !this.dialog;
    },
    // downloadScript() {
    //   this.$store.commit(SET_PAGE_LOADING, true);
    //   ApiService.post(`/authorizations/clients/download_plugin`).then((res) => {
    //     this.$store.commit(SET_PAGE_LOADING, true);
    //     try {
    //       ApiService.post(res.data.path).then((data) => {
    //         const url = window.URL.createObjectURL(new Blob([data.data]));
    //         const link = document.createElement("a");
    //         link.href = url;
    //         link.setAttribute("download", "iq-return.js");
    //         document.body.appendChild(link);
    //         link.click();
    //         this.$store.commit(SET_PAGE_LOADING, false);
    //       });
    //     } catch {
    //       this.$store.commit(SET_PAGE_LOADING, false);
    //     }
    //   });
    // },
    copyScript() {
      const snippet =
        this.script_open +
        this.script_type +
        " " +
        this.script_src +
        ">" +
        // this.close_tag +
        this.script_close +
        this.script_open +
        ">" +
        "\n" +
        this.snippet_1 +
        "\n" +
        this.snippet_20 +
        this.snippet_3 +
        "\n" +
        this.snippet_21 +
        "\n" +
        this.snippet_2 +
        `'${this.client_token}'` +
        "," +
        "\n" +
        this.snippet_12 +
        this.snippet_13 +
        "\n" +
        this.snippet_4 +
        this.snippet_5 +
        "\n" +
        this.snippet_6 +
        this.snippet_7 +
        "\n" +
        this.snippet_8 +
        this.snippet_9 +
        "\n" +
        this.snippet_10 +
        this.snippet_11 +
        "\n" +
        this.snippet_14 +
        this.snippet_15 +
        "\n" +
        this.snippet_22 +
        "\n" +
        this.snippet_16 +
        "\n" +
        this.script_close;
      navigator.clipboard.writeText(snippet);
      this.copyScriptButtonText = "copied";
    },
    copyDiv() {
      navigator.clipboard.writeText(`<div id="__iq__return"></div>`);
      this.copyScriptDivText = "copied";
    },
  },
  computed: {
    client_token: function () {
      return this.$store.getters.getItemForAction?.client_token_label;
    },
  },
};
</script>
<style scoped>
.highlight {
  position: relative;
  background: #1e1e3f;
  border-radius: 0.5rem;
  padding: 1rem;
}
.highlight .highlight-copy {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}

.highlight .highlight-copy:hover {
  transition: all 0.2s ease;
  background-color: #323268;
  color: #009ef7;
}

.highlight span {
  width: 85%;
  color: #a5c261;
  text-align: left;
  word-break: break-all;
  font-family: "Operator Mono", "Fira Code", Consolas, Monaco, "Andale Mono",
    "Ubuntu Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.token-tag {
  color: rgb(255, 157, 0) !important;
}
.token-attr {
  color: rgb(255, 180, 84) !important;
}
.token-value {
  color: #a5c261 !important;
}
</style>
