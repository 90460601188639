import { render, staticRenderFns } from "./ReturnIntegrations.vue?vue&type=template&id=265f6379&scoped=true"
import script from "./ReturnIntegrations.vue?vue&type=script&lang=js"
export * from "./ReturnIntegrations.vue?vue&type=script&lang=js"
import style2 from "./ReturnIntegrations.vue?vue&type=style&index=2&id=265f6379&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "265f6379",
  null
  
)

export default component.exports