<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" scrollable persistent max-width="1100px">
      <v-card class="sellers-actions-container">
        <v-card-title class="d-flex justify-content-between h-75px">
          <div>
            <span class="font-weight-medium m-4 text-h5"
              >{{ this.$t(actionType) }} {{ this.$t("client") }}</span
            >
          </div>
          <button @click="toggleModal">
            <span class="svg-icon mr-2">
              <v-icon size="18">mdi-close</v-icon>
            </span>
          </button>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-card class="p-5 sellers-tab-card">
              <v-tabs
                :slider-size="2"
                show-arrows
                color="#99A1B7"
                align-with-title
                v-model="activeTab"
                slider-color="#9843c4"
              >
                <v-tabs-slider color="#9843c4"></v-tabs-slider>
                <!-- Tab header starts -->
                <template v-for="(tab, index) in tabs">
                  <v-tab
                    v-if="
                      tab.key !== 'Sales' || organizationSystemCode === 'SPL'
                    "
                    :key="index"
                    :disabled="tab.disabled"
                    @change="onTabChange(tab.key)"
                  >
                    <span class="svg-icon mr-2">
                      <v-icon size="18">mdi-{{ tab.icon }}</v-icon>
                    </span>
                    {{ tab.title }}
                  </v-tab>
                </template>
                <!-- Tab header ends -->
                <!-- Tab content starts -->

                <v-tab-item>
                  <v-card flat class="d-flex flex-column">
                    <div
                      class="mt-6 d-flex flex-column flex-fill custom-tab-container"
                    >
                      <!-- Basic fields starts -->
                      <BasicFormFields
                        ref="basics"
                        :serverData="serverData.basics"
                        :pageLoader="pageLoader"
                        :refresher="refresher"
                        :actionItem="actionItem"
                      />
                      <!-- Basic fields ends -->
                    </div>
                  </v-card>
                </v-tab-item>
                <!-- Orders fields ends -->
                <v-tab-item v-if="organizationSystemCode !== 'SPL'">
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Inbound fields starts -->
                        <InboundFormFields
                          ref="inbound"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Inbound fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="organizationSystemCode !== 'SPL'">
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Outbound fields starts -->
                        <OutboundFormFields
                          ref="outbound"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Outbound fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <v-tab-item v-if="organizationSystemCode !== 'SPL'">
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Inventory fields starts -->
                        <InventoryFormFields
                          ref="inventory"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Inventory fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>
                <v-tab-item>
                  <v-card flat>
                    <v-card flat class="d-flex flex-column">
                      <div
                        class="mt-6 d-flex flex-column flex-fill custom-tab-container"
                      >
                        <!-- Sales fields starts -->
                        <SalesFormFields
                          ref="sales"
                          :pageLoader="pageLoader"
                          :refresher="refresher"
                          :actionItem="actionItem"
                        />
                        <!-- Sales fields ends -->
                      </div>
                    </v-card>
                  </v-card>
                </v-tab-item>

                <!-- Tab content ends -->
              </v-tabs>
            </v-card>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <!-- <v-btn color="blue darken-1" text @click="dialog = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Save
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import ApiService from "@/core/services/api.service";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { SET_ITEM_FOR_ACTION } from "@/core/services/store/main.module";
import { UPDATE_TABLE_DATA } from "@/core/services/store/clients.module.js";

import BasicFormFields from "@/own/components/customers/clients/FormParts/BasicFormFields.vue";
import InboundFormFields from "@/own/components/customers/clients/FormParts/InboundFormFields.vue";
import OutboundFormFields from "@/own/components/customers/clients/FormParts/OutboundFormFields.vue";
import InventoryFormFields from "@/own/components/customers/clients/FormParts/InventoryFormFields.vue";
import SalesFormFields from "@/own/components/customers/clients/FormParts/SalesFormFields.vue";

export default {
  name: "ActionDialog",
  components: {
    BasicFormFields,
    InboundFormFields,
    OutboundFormFields,
    InventoryFormFields,
    SalesFormFields,
  },
  data: () => ({
    dialog: false,
    serverData: {
      basics: null,
    },
    activeTab: 0,
    actionType: "",
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) {
        if (!this.actionItem) {
          this.loadServerData("create").then(() => {
            this.activeTab = 0;
            this.dialog = true;
            this.actionType = "Create";
          });
        } else {
          this.loadServerData("edit").then(() => {
            this.activeTab = 0;
            this.dialog = true;
            this.actionType = "Edit";
          });
        }
      } else {
        this.$store.commit(SET_ITEM_FOR_ACTION, null);
        this.dialog = false;
        this.reset();
      }
    },
    loadServerData(slug) {
      this.pageLoader(true);
      let payload = {
        tab_name: "basic",
      };

      if (this.actionItem) {
        payload.id = this.actionItem.id;
      }

      return ApiService.post(`authorizations/clients/${slug}`, payload)
        .then(({ data }) => {
          this.serverData.basics = data;
        })
        .then(() => {
          if (this.actionItem) {
            setTimeout(() => {
              this.$refs.basics.getFormData();
            }, 50);
          }
        })
        .finally(() => {
          this.pageLoader(false);
        });
    },
    onTabChange(tab) {
      // console.log(tab);
      setTimeout(() => {
        if (tab === "Inbound") {
          this.$refs.inbound.loadServerData();
        } else if (tab === "Outbound") {
          this.$refs.outbound.loadServerData();
        } else if (tab === "Inventory") {
          this.$refs.inventory.loadServerData();
        } else if (tab === "Sales") {
          this.$refs.sales.loadServerData();
        }
      });
    },
    pageLoader(val = true) {
      this.$store.commit(SET_PAGE_LOADING, val);
    },
    reset() {
      this.serverData = {
        basics: {
          divisions: [],
          client_types: [],
          aggregators: [],
        },
      };

      //Reset forms validations
      this.$refs.basics.reset();
      if (this.$refs["inbound"]) this.$refs.inbound.reset();
      if (this.$refs["outbound"]) this.$refs.outbound.reset();
      if (this.$refs["inventory"]) this.$refs.inbound.reset();
      if (this.$refs["sales"]) this.$refs.sales.reset();
    },
    isTabDisabled() {
      if (this.activeTab !== 0 && !this.actionItem) {
        return true;
      }
      return false;
    },
    isTabVisible(key) {
      if (key === "Sales") {
        if (this.organizationSystemCode === "SPL") {
          return false; // Return false if key is "Sales" and organizationSystemCode is "SPL"
        } else {
          return true; // Return true otherwise
        }
      } else {
        return true; // Return true for all other keys
      }
    },
    refresher() {
      this.$store.dispatch(UPDATE_TABLE_DATA, this.tableState);
    },
  },
  computed: {
    actionItem: function () {
      return this.$store.getters.getItemForAction;
    },
    tabs: function () {
      const tabs = [
        {
          title: this.$t("basic_configurations"),
          key: "Basics",
          icon: "home",
          hide: false,
          disabled: false,
        },
        {
          title: this.$t("inbound"),
          key: "Inbound",
          icon: "call-received",
          hide: this.isOrganizationSPL,
          disabled: !this.actionItem,
        },
        {
          title: this.$t("outbound"),
          key: "Outbound",
          icon: "call-made",
          hide: this.isOrganizationSPL,
          disabled: !this.actionItem,
        },
        {
          title: this.$t("inventory"),
          key: "Inventory",
          icon: "adjust",
          hide: this.isOrganizationSPL,
          disabled: !this.actionItem,
        },
        {
          title: this.$t("sales"),
          key: "Sales",
          icon: "cart-outline",
          hide: false,
          disabled: !this.actionItem,
        },
      ];

      return tabs.filter((tab) => !tab.hide);
    },
    organizationSystemCode: function () {
      return this.$store.getters.organizationSystemCode;
    },
    tableState: function () {
      return this.$store.getters.getCLIENTTableState;
    },
    isOrganizationSPL() {
      return (
        this.$store.getters.currentUser.data.organization_system_code === "SPL"
      );
    },
  },
};
</script>

<style lang="scss">
.sellers-tab-card {
  border: 1px solid #f1f1f4 !important;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.03) !important;
  // min-height: 560px;
}
.no-scrollbar {
  scrollbar-width: none;
}
.sellers-actions-container {
  .v-card__text {
    overflow: hidden !important;
  }
}
.custom-tab-container {
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100vh - 300px) !important;
}
</style>
