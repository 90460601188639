<template>
  <div>
    <div
      class="ma-0 row-bg-color custom-content-height"
      v-if="$store.getters.getRETURNINTEGRATIONProperties.properties"
    >
      <div class="bg-white rounded-lg h-100 py-8 pl-0 px-sm-6 scroll-y">
        <datatable-detail
          class="col-9 col-md-9 col-sm-9"
          :table_properties="
            $store.getters.getRETURNINTEGRATIONProperties.properties
          "
        />
        <div class="my-10 ml-4" :class="{ 'text-right pr-4': $vuetify.rtl }">
          <h3 class="font-size-h1 text-dark-65">
            {{ $t("overview") }}
          </h3>
          <p class="text-dark-65 font-size-h4 w-1000px mt-3">
            {{ $t("our_return_plugin_can_be_easily_integrated") }}
          </p>
          <div class="row mt-8">
            <div class="col-lg-8">
              <!-- Usage -->
              <!-- <div v-if="!isClient"> -->
              <div>
                <h3 class="font-size-h1 text-dark-65">
                  {{ $t("usage") }}
                </h3>
                <h6 class="mt-6 mb-6 text-dark-65">
                  {{ $t("integrate_with_the_following_endpoint") }}
                </h6>
                <div class="highlight">
                  <button
                    class="btn"
                    :class="{
                      'highlight-copy-rtl': $vuetify.rtl,
                      'highlight-copy': !$vuetify.rtl,
                    }"
                    @click="copyScript"
                  >
                    {{ copyScriptButtonText }}
                  </button>
                  <div class="highlight-code">
                    <span class="token-tag">{{ script_open }}</span>
                    <span class="token-value">{{ script_type }} </span>
                    <br />
                    <span class="token-value">
                      {{ script_src }}
                    </span>
                    <span class="token-tag"
                      >{{ close_tag }}<br />
                      {{ script_close }}</span
                    >
                    <br />
                    <span class="token-tag">{{ script_open }}</span>
                    <br />
                    <span class="token-attr ml-6">{{ snippet_1 }}</span>
                    <br />
                    <span class="token-tag ml-8">{{ snippet_20 }}</span>
                    <span class="token-value ml-2">{{ snippet_3 }}</span>
                    <br />
                    <span class="token-tag ml-8">{{ snippet_21 }}</span>
                    <br />
                    <span class="token-tag ml-12">{{ snippet_2 }}</span>
                    <span v-if="token" class="token-value ml-2"
                      >'{{ token }}',</span
                    >
                    <span v-else class="token-value ml-2"
                      >'{{ generated_token }}',</span
                    >
                    <br />
                    <span class="token-tag ml-12">{{ snippet_12 }}</span>
                    <span class="token-value ml-2">{{ snippet_13 }}</span>
                    <br />
                    <span class="token-tag ml-12">{{ snippet_4 }}</span>
                    <span class="token-value ml-2">{{ snippet_5 }}</span>
                    <br />
                    <span class="token-tag ml-12">{{ snippet_6 }}</span>
                    <span class="token-value ml-2">{{ snippet_7 }}</span>
                    <br />
                    <span class="token-tag ml-12">{{ snippet_8 }}</span>
                    <span class="token-value ml-2">{{ snippet_9 }}</span>
                    <br />
                    <span class="token-tag ml-12">{{ snippet_10 }}</span>
                    <span class="token-value ml-2">{{ snippet_11 }}</span>
                    <br />
                    <span class="token-tag ml-12">{{ snippet_14 }}</span>
                    <span class="token-value ml-2">{{ snippet_15 }}</span>
                    <br />
                    <span class="token-tag ml-8">{{ snippet_22 }}</span>
                    <br />
                    <span class="token-attr ml-4"> {{ snippet_16 }}</span>
                    <br />
                    <span class="token-tag"> {{ script_close }}</span>
                  </div>
                </div>
                <h6 class="mt-10 my-6">
                  {{ $t("add_a_div_with_proper_id_in_your_document_body") }}
                </h6>
                <div class="highlight highlight-code">
                  <button
                    class="btn"
                    :class="{
                      'highlight-copy-rtl': $vuetify.rtl,
                      'highlight-copy': !$vuetify.rtl,
                    }"
                    @click="copyDiv"
                  >
                    {{ copyScriptDivText }}
                  </button>
                  <span class="token-tag">
                    <span class="token-tag"> {{ snippet_17 }}</span>
                    <span class="token-value">{{ snippet_18 }}</span>
                    <span class="token-tag">{{ snippet_19 }}</span>
                  </span>
                  <br />
                </div>
              </div>
              <!-- <div v-else>
                <h3 class="font-size-h1 text-dark-65">Usage</h3>
                <h6 class="text-dark-65 mt-8">Your token</h6>
                <div class="highlight">
                  <button class="highlight-copy btn" @click="copyToken">
                    {{ copyScriptDivText }}
                  </button>
                  <span class="token-tag">
                    <span v-if="!token_generated" class="token-value">{{
                      token
                    }}</span>
                    <span v-else class="token-value">{{
                      generated_token
                    }}</span>
                  </span>
                  <br />
                </div>
              </div> -->
              <!-- References -->
              <h3 class="font-size-h1 text-dark-65 mt-24">
                {{ $t("api_references") }}
              </h3>
              <div class="table-responsive border rounded mt-8">
                <table class="table table-striped align-top mb-0 g-5">
                  <thead>
                    <tr class="fs-4 fw-bold text-dark p-6">
                      <th class="min-w-250px">
                        {{ $t("name") }}
                      </th>
                      <th class="min-w-100px">
                        {{ $t("type") }}
                      </th>
                      <th class="min-w-500px">
                        {{ $t("description") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <code>id</code>
                      </td>
                      <td>
                        <code class="ms-0"> mandatory </code>
                      </td>
                      <td>
                        {{ $t("enables_the_element_to_render_plugin_scripts") }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>clientId</code>
                      </td>
                      <td>
                        <code class="ms-0">mandatory</code>
                      </td>
                      <td>
                        {{
                          $t(
                            "a_token_identifier_to_authorize_validate_requests_sent_to_api"
                          )
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>inputTextColor</code>
                      </td>
                      <td>
                        <code class="ms-0">optional</code>
                      </td>
                      <td>
                        {{ $t("input_fields_color") }} <br />
                        <code class="ml-0">{{ $t("default") }}: #292930</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>bgColor</code>
                      </td>
                      <td>
                        <code class="ms-0">optional</code>
                      </td>
                      <td>
                        {{ $t("background_color") }} <br />
                        <code class="ml-0">{{ $t("default") }}: #ffffff</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>headerTextColor</code>
                      </td>
                      <td>
                        <code class="ms-0">optional</code>
                      </td>
                      <td>
                        {{ $t("section_title_text_colors") }}
                        <br />
                        <code class="ml-0">{{ $t("default") }}: #292930</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>btnBgColor</code>
                      </td>
                      <td>
                        <code class="ms-0">optional</code>
                      </td>
                      <td>
                        {{ $t("submit_button_background") }}
                        <br />
                        <code class="ml-0">{{ $t("default") }}: #8c71db</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>btnTextColor</code>
                      </td>
                      <td>
                        <code class="ms-0">optional</code>
                      </td>
                      <td>
                        {{ $t("submit_button_text_color") }}
                        <br />
                        <code class="ml-0">{{ $t("default") }}: #8c71db</code>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <code>lang</code>
                      </td>
                      <td>
                        <code class="ms-0">optional</code>
                      </td>
                      <td>
                        {{ $t("preferred_language") }} <br />
                        <code class="ml-0">{{ $t("options") }}: en | ar</code>
                        <code class="ml-0">{{ $t("default") }}: en</code>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- Get token -->
              <h3 class="font-size-h1 text-dark-65 mt-24 mb-10">
                {{ $t("token_configuration") }}
              </h3>
              <div class="d-flex">
                <div v-if="!isClient" class="w-300px">
                  <v-select
                    class="w-100"
                    v-model="client_id"
                    @change="getToken"
                    :label="$t('select_client')"
                    item-text="text"
                    item-value="index"
                    :items="return_properties.clients"
                    outlined
                  ></v-select>
                </div>
                <div :class="['d-flex', { 'ml-20': !isClient }]">
                  <div>
                    <b-btn
                      v-if="!isClient && got_token"
                      variant="primary"
                      class="btn--export-filter rounded-lg p-5 mr-8"
                      @click="showToken"
                      >{{ $t("show_token") }}</b-btn
                    >
                  </div>
                  <div>
                    <button
                      class="btn btn--export-filter p-5"
                      @click="generateRefreshToken"
                    >
                      {{ $t("refresh_token") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TokenModal :refresh="generated_token" ref="tokenModal" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import Swal from "sweetalert2";

import { SET_PAGE_LOADING } from "@/core/services/store/config.module";
import { UPDATE_RETURN_INTEGRATION_STRUCTURE } from "@/core/services/store/return_integration.module";
import DatatableDetail from "@/own/components/datatable/DatatableDetail";
import TokenModal from "@/own/components/returnIntegration/TokenModal";

export default {
  name: "ReturnIntegrations",
  components: {
    DatatableDetail,
    TokenModal,
  },
  data: function () {
    return {
      dialog: false,
      script_open: "<script",
      script_type: ` type="text/javascript"`,
      script_src:
        'src="https://test.api.iqhybrid.com/files/spl_return_plugin/iq-return.min.js"',
      close_tag: ">",
      script_close: `</scr` + `ipt>`,
      snippet_1: `     new IqReturn({`,
      snippet_2: "clientId:",
      snippet_3: "'__iq__return',",
      snippet_12: "inputTextColor:",
      snippet_13: "'#292930',",
      snippet_4: "bgColor:",
      snippet_5: "'#ffffff',",
      snippet_6: "headerTextColor:",
      snippet_7: "'#292930',",
      snippet_8: "btnBgColor:",
      snippet_9: "'#8c71db',",
      snippet_10: "btnTextColor:",
      snippet_11: "'#ffffff',",
      snippet_14: "lang:",
      snippet_15: "'en'",
      snippet_16: "});",
      snippet_17: "<div",
      snippet_18: ` id="__iq__return"`,
      snippet_19: "></div>",
      snippet_20: `id: `,
      snippet_21: `options: {`,
      snippet_22: `}`,
      copyScriptButtonText: "copy",
      copyScriptDivText: "copy",
      client_id: null,
      generated_token: "YOUR_TOKEN",
      token_generated: false,
      got_token: false,
    };
  },
  methods: {
    async initTableContent() {
      await this.$store.dispatch(UPDATE_RETURN_INTEGRATION_STRUCTURE, {});
      if (!this.isClient) {
        const client = this.return_properties?.clients[0].index;
        if (client) {
          await this.getToken(client);
        }
      }
    },
    copyScript() {
      const snippet =
        this.script_open +
        this.script_type +
        " " +
        this.script_src +
        ">" +
        // this.close_tag +
        this.script_close +
        this.script_open +
        ">" +
        "\n" +
        this.snippet_1 +
        "\n" +
        this.snippet_20 +
        this.snippet_3 +
        "\n" +
        this.snippet_21 +
        "\n" +
        this.snippet_2 +
        `'${
          this.token && !this.token_generated
            ? this.token
            : this.generated_token
        }'` +
        "," +
        "\n" +
        this.snippet_12 +
        this.snippet_13 +
        "\n" +
        this.snippet_4 +
        this.snippet_5 +
        "\n" +
        this.snippet_6 +
        this.snippet_7 +
        "\n" +
        this.snippet_8 +
        this.snippet_9 +
        "\n" +
        this.snippet_10 +
        this.snippet_11 +
        "\n" +
        this.snippet_14 +
        this.snippet_15 +
        "\n" +
        this.snippet_22 +
        "\n" +
        this.snippet_16 +
        "\n" +
        this.script_close;
      navigator.clipboard.writeText(snippet);
      this.copyScriptButtonText = "copied";
    },
    copyDiv() {
      navigator.clipboard.writeText(`<div id="__iq__return"></div>`);
      this.copyScriptDivText = "copied";
    },
    generateRefreshToken() {
      if (this.customerId) {
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(`/shipping/returns_extensions/refresh_token`, {
          id: this.customerId,
        })
          .then(({ data }) => {
            this.generated_token = data.token;
            this.token_generated = true;
            this.$refs.tokenModal.toggleModal();
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    getToken(id = null) {
      const customerId = id || this.client_id;
      this.client_id = customerId;
      if (this.client_id) {
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(`/shipping/returns_extensions/get-client-token`, {
          id: customerId,
        })
          .then(({ data }) => {
            this.generated_token = data.token;
            this.got_token = true;
            // this.$refs.tokenModal.toggleModal();
            this.$store.commit(SET_PAGE_LOADING, false);
          })
          .catch(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    showToken() {
      this.$refs.tokenModal.toggleModal();
    },
    copyToken() {
      navigator.clipboard.writeText(
        !this.token_generated ? this.token : this.generated_token
      );
      this.copyScriptDivText = "copied";
    },
  },
  computed: {
    return_properties: function () {
      return this.$store.getters.getRETURNINTEGRATIONProperties;
    },
    token: function () {
      return this.$store.getters.getRETURNINTEGRATIONProperties.token;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
      // return true;
    },
    customerId: function () {
      return this.isClient
        ? this.$store.getters.currentUser.data.customer_id
        : this.client_id;
    },
  },
  async mounted() {
    this.$store.commit(SET_PAGE_LOADING, true);
    await this.initTableContent();
  },
};
</script>
<style scoped v-if="$vuetify.rtl"></style>
<style scoped v-else></style>
<style scoped>
.highlight {
  position: relative;
  background: #1e1e3f;
  border-radius: 0.5rem;
  padding: 1rem;
}
.highlight .highlight-copy {
  position: absolute;
  right: 1.75rem;
  top: 1.5rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}
.highlight .highlight-copy-rtl {
  position: absolute;
  right: 1.75rem;
  top: 1rem;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0.35rem 1rem !important;
  transition: all 0.2s ease;
  background-color: #2d2d5e;
  color: rgba(255, 255, 255, 0.75);
}

.highlight .highlight-copy:hover {
  transition: all 0.2s ease;
  background-color: #323268;
  color: #009ef7;
}

.highlight span {
  width: 85%;
  color: #a5c261;
  text-align: left;
  word-break: break-all;
  font-family: "Operator Mono", "Fira Code", Consolas, Monaco, "Andale Mono",
    "Ubuntu Mono", monospace;
  font-weight: 400;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.5px;
}
.token-tag {
  color: rgb(255, 157, 0) !important;
}
.token-attr {
  color: rgb(255, 180, 84) !important;
}
.token-value {
  color: #a5c261 !important;
}
code {
  color: #b93993 !important;
}
.highlight-code {
  direction: ltr !important;
  text-align: left !important;
}
</style>
